
import { LANDING_PAGE_IDS, QUIZ_VARIANTS, REGIONS } from '@/services/constants';

export default {
  components: {
    LandingPagesLoseWeight: () => import('@/components/landing-pages/lose-weight/lose-weight'),
  },
  asyncData({ store }) {
    store.dispatch('quiz/setQuizVariantAction', QUIZ_VARIANTS.PILATES_28_D);
    store.dispatch('quiz/setLandingPageIdAction', LANDING_PAGE_IDS.PILATES_28_D);
  },
  computed: {
    regionAndLanguageEnabled() {
      return (
        [REGIONS.us, REGIONS.au, REGIONS.uk, REGIONS.join].includes(this.$countryCode) && this.getLanguage === 'en'
      );
    },
    shouldShowLanding() {
      return this.regionAndLanguageEnabled && this.getQuizVariant === QUIZ_VARIANTS.PILATES_28_D;
    },
  },
  mounted() {
    this.landingPageGtmHandler();
  },
};
